.status-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ff4c4c;
    color: white;
    text-align: center;
    padding: 10px;
    z-index: 1200;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .close-button {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    margin-left: 20px;
  }
  