/* Add this CSS to style the image upload section */
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border: 2px dashed #dddddd;
  border-radius: 10px;
  cursor: pointer;
  transition: border 0.3s ease-in-out;
}
.upload-button{
  width: 100%;
}
.button-text{
  width: 100%;
}
.upload-container:hover {
  border: 2px dashed #007bff; /* Change to your desired border color */
}

.custom-label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.upload-icon {
  font-size: 24px;
  margin-right: 5px;
}

/* Hide the file input */
.file-input {
  display: block;
}
.file-input:invalid + .error-message {
  display: block; /* Show the error message when the input is invalid (e.g., not selected) */
}

.error-message {
  display: block; /* Initially hide the error message */
  color: red; /* You can style the error message as needed */
  margin-top: 5px; /* Add some spacing */
}
/* Hide the button, but keep the input element */
.upload-button input[type="file"] {
  display: none;
}
