.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.loading-container p {
  margin-top: 16px;
  font-size: 16px;
  color: #757575;
}

.search-container-right {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.header-bold {
  font-weight: bold;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.page-info {
  font-size: 14px;
  color: #757575;
}
