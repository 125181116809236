.trade-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.trade-card {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.trade-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #1a1a1a;
  color: #fff;
  border-radius: 10px;
}

.trade-user-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.trade-avatar {
  width: 50px;
  height: 50px;
  background-color: #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.trade-details {
  text-align: center;
  margin-bottom: 20px;
}

.trade-details h6 {
  font-size: 1.25rem;
  margin-top: 10px;
}

.trade-result-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.trade-timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.timer-svg {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 2.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  transition: stroke-dasharray 0.4s ease, stroke 0.4s ease;
}

.timer-text {
  fill: #fff;
  font-size: 0.5em;
  text-anchor: middle;
}

.timer-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.btn-close,
.btn-continue {
  background-color: #555;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.btn-close:hover {
  background-color: #333;
}

.btn-continue {
  background-color: #1e88e5;
}

.btn-continue:hover {
  background-color: #1565c0;
}
